/* autoprefixer grid: autoplace */
html,
body,
#securemailapp,
#main-body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

html {
  position: fixed;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #555;
}

h1,
h2,
h3 {
  line-height: 1.15;
}

h4,
h5,
h6 {
  line-height: 1.3;
}

.logo {
  background: url('../img/brightsquid.svg') no-repeat left center;
  height: 46px;
  margin: 8px 15px;
  background-position: left center;
}

.login .logo {
  max-width: 300px;
  background-position: center;
  margin: auto;
}

/***********************************
 KEYFRAME ANIMATIONS
***********************************/

@-webkit-keyframes slideDown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 2000px;
  }
}

@keyframes slideDown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 2000px;
  }
}

.slide-down {
  overflow: hidden;
  -webkit-animation: slideDown 2s ease; /* Chrome, Safari, Opera */
  animation: slideDown 2s ease;
}

/***********************************
 TEXT STYLES
***********************************/
.text-lighter-grey {
  color: #999;
}

.text-light-grey {
  color: #777;
}

.text-grey {
  color: #555;
}

.text-dark-grey {
  color: #333;
}

.text-black {
  color: #111;
}

.text-blue {
  color: #2985c9;
}

.text-red {
  color: #dd4040;
}

.text-orange {
  color: #ff9000;
}

.text-brown {
  color: #bb8055;
}

.text-purple {
  color: #955ab7;
}

.text-manila {
  color: #eada9a;
}

.text-periwinkle {
  color: #85a5d0;
}

.text-green {
  color: #3b3;
}

.text-greenish {
  color: #92c935;
}

.text-white {
  color: #fff !important;
}

.text-tint-20 {
  color: rgba(0, 0, 0, 0.2);
}

.text-tint-50 {
  color: rgba(0, 0, 0, 0.5);
}

/* TEXT ALIGN */
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* TEXT WEIGHT */
.text-light {
  font-weight: lighter;
}

.text-normal {
  font-weight: normal;
}

.text-bold {
  font-weight: bolder;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.requiredInput {
  min-width: 240px !important;
}

.ul-popup {
  width: 50%;
  margin: auto;
  left: 50px;
  text-align: left;
}

.popup-text {
  max-width: 550px;
  margin: auto;
}

.strike-through,
.strike-through:hover,
.strike-through:focus,
.strike-through:active {
  text-decoration: line-through !important;
}

/* LISTS */
ol.lower-alpha {
  list-style-type: lower-alpha;
}

.flush-list {
  padding-left: 20px;
}

/***********************************
 HELPERS
***********************************/
.bg-tint-20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-tint-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-off-white {
  background-color: #f9f9f9;
}

.bg-greenish {
  background-color: #92c935;
}

.bg-green {
  background-color: #3b3;
}

.bg-orange {
  background-color: #ff9000;
}

.bg-red {
  background-color: #de1b1b;
}

.bg-blue {
  background-color: #2985c9;
}

.bg-purple {
  background-color: #955ab7;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.width-100 {
  width: 100%;
}

.max-form-width {
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
}

.height-100 {
  height: 100%;
}

.font-15 {
  font-size: 15px;
}

.btn-none {
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
}

.weight-600 {
  font-weight: 600;
}

.opacity-50 {
  opacity: 0.5;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.margin-10 {
  margin: 10px !important;
}

.margin-right {
  margin-right: 10px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.no-margin {
  margin: 0 !important;
}

.auto-margin {
  margin: auto;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-vertical-15 {
  padding: 15px 0;
}

.padding-horizontal-15 {
  padding: 0 15px;
}

.padding-left {
  padding-left: 10px;
}

.padding-right {
  padding-right: 10px;
}

.clear-both {
  clear: both;
}

.full-screen {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  overflow: auto;
}

.ellipsis {
  max-width: 100%;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-no-style {
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
}

.invisible {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.highlight {
  background-color: #ff8;
}

.inline-block {
  display: inline-block;
}

.v-top {
  vertical-align: top;
}

.v-middle {
  vertical-align: middle;
}

.btn-no-style {
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
}

.flexbox {
  display: flex;
}

.flexbox-center {
  align-self: center;
  margin: auto !important;
}

.flex {
  flex: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-tabs {
  display: flex;
}

.flex-tabs > li {
  display: inline-flex;
  flex: 1;
  max-width: 50%;
  padding: 0 3px;
  margin: 0 0 -1px;
}

.flex-tabs > li > a {
  width: 100%;
  padding: 12px;
  text-align: center;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.spinner {
  display: inline-block;
  margin: 0 10px;
  opacity: 0.7;
}

.spinner {
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.half,
.third,
.quarter,
.fifth,
.sixth {
  display: inline-block;
  vertical-align: top;
}

.half {
  width: 50%;
  flex-basis: 50%;
}

.third {
  width: 33.3333%;
  flex: 33.3333%;
}

.quarter {
  width: 25%;
  flex-basis: 25%;
}

.fifth {
  width: 20%;
  flex-basis: 20%;
}

.sixth {
  width: 15%;
  flex-basis: 15%;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery .half,
.gallery .third,
.gallery .quarter,
.gallery .fifth,
.gallery .sixth {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.gallery .thumbnail,
.gallery .thumbnail-large {
  margin: 2px;
  padding: 0;
  border: 1px;
  border-color: #fff;
  background-color: #eef0f2;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.gallery .thumbnail {
  background-size: cover;
  height: 139px;
  width: calc(33.333% - 4px);
}

.gallery .thumbnail-large {
  background-size: contain;
  width: 100%;
  height: 425px;
  outline: 0;
}

.gallery .thumbnail > img,
.gallery .thumbnail-large > img {
  max-width: 100%;
  max-height: 100%;
}

.gallery .video-viewer-video {
  margin: 2px;
  width: 100%;
  height: 425px;
  background-color: #eef0f2;
}

.gallery .play-button {
  padding: 30px;
}

.gallery .thumbnail.selected {
  border: 1px solid;
  border-color: #2985c9;
}

@media (max-width: 767.98px) {
  .gallery .switch {
    order: -1;
  }

  .gallery .half {
    width: 100%;
    height: 84vw;
    flex-basis: 100%;
  }

  .gallery .thumbnail {
    height: 33.333%;
  }

  .gallery .thumbnail-large {
    height: auto;
  }

  .gallery .video-viewer-video {
    height: auto;
  }
}

.pending-approval {
  display: flex;
  flex: 1;
  align-items: center;
  color: #555;
  text-align: center;
}

.notification-dialog,
.notification-dialog h4 {
  color: #333;
}

.notification-dialog ul {
  padding-left: 25px;
}

.notification-dialog ul li {
  margin: 5px 0;
}

.ie .Select-input {
  width: 100%;
}

.ie .Select-input > input {
  min-width: 100%;
}

.sv_q_other {
  background-color: rgba(255, 255, 255, 0.85) !important;
}

.Select-option.is-disabled {
  color: #7b7b7b;
}

/***********************************
 ALIGNER BUTTON STYLES
***********************************/

.btn-aligner {
  height: 40px;
  padding: 8px 20px;
  border: 0;
  border-radius: 20px;
  background-color: #92c935;
  color: #fff;
}

.btn-aligner:hover {
  background-color: color(#92c935 shade(5%));
}

.btn-aligner[disabled] {
  opacity: 0.4;
}

.btn-aligner:hover[disabled] {
  background-color: #92c935;
}

/***********************************
 PROGRESS BAR STYLES
***********************************/

.progress-header {
  position: absolute;
  width: 100%;
  max-height: 4px;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  font-weight: 300;
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
  z-index: 1000;
  transition: max-height 1s 0.2s ease;
}

.progress-header:hover,
.progress-header.open {
  max-height: 100%;
  overflow: auto;
  transition: max-height 0.5s ease;
}

.progress-group {
  padding: 20px 10px;
}

.progress-group + .progress-group {
  padding-top: 0;
}

.progress-group h4 {
  margin: 0;
  padding: 0 20px 0 0;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.progress-group-head {
  margin: 5px 20px 0;
}

.progress-attachments.flexbox {
  flex-wrap: wrap;
}

.progress-attachments > .flex {
  max-width: 100%;
  min-width: 250px;
  flex: 1 0 15%;
  padding: 10px 20px 0;
}

.progress-attachments .ellipsis {
  max-width: 90%;
  padding-right: 10px;
  flex: 10;
}

.progress-attachments .percent {
  flex: 1;
  text-align: right;
}

/* Bootstrap also has a progress-bar class, so we need overrides here*/

.progress-attachments .progress-bar {
  float: none !important;
  margin: 5px 0;
}

.pager .disabled button.btn.btn-link,
.pager .disabled button.btn.btn-link:hover,
.pager .disabled button.btn.btn-link:active,
.pager .disabled button.btn.btn-link:focus {
  color: #ccc;
  text-decoration: none;
  cursor: not-allowed;
}

.sm-btn .input-group-addon {
  width: 40px;
}

.sm-btn {
  float: left;
  display: flex;
  margin-right: 20px;
}

.templates-control {
  min-height: 35px;
  padding: 6px 12px;
}

/***********************************
 GENERAL STYLES
***********************************/

.icon-inline {
  margin-right: 10px;
}

.icon-read-receipt:before,
.icon-unread-receipt:before,
.icon-unknown-receipt:before {
  display: inline-block;
  font-family: Ionicons;
  font-style: normal;
  margin: 0 5px 0 4px;
  transform: scale(1.2);
}

.icon-read-receipt:before {
  content: '\f3fe';
  color: #3b3;
}

.icon-unread-receipt:before {
  content: '\f401';
  color: #ff9000;
}

.icon-unknown-receipt:before {
  content: '\f463';
  color: #de1b1b;
}

a,
a:hover {
  color: #2985c9;
}

.logo-link {
  outline: 0;
}

.coming-soon {
  float: right;
  color: #ff0000c2;
  line-height: 25px;
  font-size: 12px;
  font-style: italic;
}

.side-panel {
  position: absolute;
  background-color: #eef0f2;
  width: 260px;
  height: 100%;
  user-select: none;
  -webkit-user-select: none;
  transition: width 0.3s ease;
}

.side-panel-scroll {
  height: calc(100% - 60px);
  padding-bottom: 30px;
  overflow-x: 0;
  overflow-y: auto;
}

.side-panel li a {
  color: #555;
  font-weight: 400;
  outline: 0;
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 10px !important;
}

/* override to prevent shifting of side-panel on hover */
.side-panel .nav-stacked > li {
  margin-top: 2px;
}

.side-panel .nav-pills > li.active > a,
.side-panel .nav-pills > li.active > a:hover,
.side-panel .nav-pills > li.active > a:focus {
  color: #555;
  background-color: rgba(0, 0, 0, 0.05);
}

.side-panel li a:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.side-panel .nav-pills .badge {
  position: absolute;
  margin-top: 2px;
  right: 15px;
  background-color: #2985c9;
  color: #fff;
  font-weight: 400;
  line-height: 1.1;
}

.no-alerts {
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: #818181;
}

.no-alerts svg {
  font-size: 50px;
  margin-bottom: 30px;
}

.alerts {
  background-color: #2985c9;
}

.alert-badge {
  top: -10px !important;
  background: #f44336;
  line-height: 16px;
  border-radius: 10px;
  vertical-align: middle;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  position: relative;
}

.szh-menu {
  position: fixed !important;
  z-index: 111;
  left: 5px !important;
  top: 45px !important;
}

.side-panel .nav-pills .active .badge {
  background-color: #2985c9;
  color: #fff;
}

.side-panel li .svg-inline--fa,
.side-panel li [class^='ion'] {
  display: inline-block;
  font-size: 17px;
  width: 16px;
  margin-right: 12px;
  text-align: center;
}

.side-panel li .ion-trash-b,
.side-panel li .ion-person-stalker {
  transform: scale(1.1);
}

.side-panel li.active .svg-inline--fa,
.side-panel li.active [class^='ion'] {
  color: #2985c9;
}

.side-panel-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 15px;
}

.side-panel-title {
  font-size: 12px;
  margin: 10px 15px;
  font-weight: 600;
  color: #777;
}

.side-panel-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  line-height: 1.5;
  padding: 10px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background-color: color(#eef0f2 shade(3%));
  font-size: 13px;
  font-weight: normal;
  text-align: center;
}

.side-panel-footer a {
  float: none;
  outline: 0;
  margin: 0;
}

.side-panel-scroll .popover,
.side-panel .popover {
  width: 400px;
  font-size: 14px;
}

.empty-trash {
  margin-bottom: 5px;
}

.main-panel {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  overflow: hidden;
  left: 260px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  transition: left 0.3s ease;
}

.side-panel-toggle .main-panel {
  left: 0;
  transition: left 0.3s ease;
}

.external-link-icon {
  color: #00000038;
  float: right;
}

.content-placeholder {
  position: relative;
  width: 100%;
  text-align: center;
}

.content-placeholder-center {
  margin-top: calc(50vh - 50px);
}

.content-placeholder-center [class*='fa-'],
.content-placeholder-center [class^='ion'] {
  background-color: #fff;
  text-align: center;
  font-size: 42px;
  line-height: 0;
}

.content-placeholder-message {
  font-weight: normal;
  color: #777;
}

.footer {
  padding: 0 10px;
  position: absolute;
  height: 50px;
  width: 100%;
  font-size: 13px;
  bottom: 0;
  border-top: 1px solid #eee;
  box-shadow: 0 -1px 15px rgba(0, 0, 0, 0.05);
}

.footer .navbar-text {
  line-height: 26px;
  outline: 0;
}

.btn-footer {
  margin-top: 15px;
  margin-bottom: 15px;
}

.btn-footer .btn + .btn {
  margin-left: 10px;
}

a {
  cursor: pointer;
}

.bg-faint-white {
  background-color: #f4f5f6;
}

.login {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f3f5f7;
  overflow: auto;
}

.sms .sign-up {
  background: #2985c9;
  color: white;
}

.sms .login-btn {
  background-color: #ffffff !important;
  color: #1074ae !important;
  font-weight: bold !important;
}

.sms .login .center-h {
  flex: none;
}

.login .center-h {
  flex: 1;
  align-self: center;
  margin: 0 auto;
  padding-bottom: 50px;
  max-width: 600px;
}

.otp-verification {
  border: 1px solid #ccc;
  padding: 0 20px 20px 20px;
  background: white;
  margin: 20px;
}

.otp-inputstyle {
  width: 3rem !important;
  text-align: center !important;
  height: 5rem;
  margin: 0px 1rem;
  font-size: 3rem;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

.otp-verify {
  border: 1px solid #ccc;
  padding: 0 10px 15px 20px;
  border-top: none;
}

.otp-helper {
  color: rgb(130 130 130);
  font-style: italic;
}

.btn-link.btn-default:active:hover,
.btn-link.btn-default:hover,
.btn-link.btn-default.disabled:active:hover,
.btn-link.btn-default.disabled:hover {
  background-color: transparent;
  border: transparent;
}

.f-input-group button.f-btn.link:disabled {
  background: #f9f9f9 !important;
  border-color: #cacaca !important;
  opacity: 1;
  color: #ccc;
  text-decoration: none;
}

.pre-send-notice .f-input-group {
  gap: 8px;
}

.pre-send-notice .f-row {
  align-items: center;
}

.pre-send-notice textarea.form-control {
  border-right-color: #ccc !important;
}

.login .center-v {
  margin-top: 18vh;
  padding: 10px;
  color: #555;
}

.NO_MATCH .login .center-v {
  margin-top: 10vh;
}

.entry-form {
  padding: 30px 15px;
  width: 100%;
  max-width: 480px;
}

.login-title,
.entry-title {
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 1.3;
  text-align: center;
  padding: 0px 30px;
}

.login input,
.entry-page input {
  border-color: rgba(0, 0, 0, 0.15);
}

.login .help-link,
.entry-page .help-link {
  text-decoration: underline;
}

.login-buttons,
.entry-buttons {
  margin-top: 30px;
  text-align: center;
}

.login .login-btn {
  background-color: #1074ae;
}

.login a {
  color: #1074ae;
}

.login-btn,
.entry-btn {
  margin-bottom: 20px;
  width: 100%;
}

.login .error-status,
.entry-page .error-status {
  margin: 5px 0;
  color: #dd4040;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.success-status {
  margin: 5px 0;
  color: #28a745 !important;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.login-footer,
.entry-footer {
  position: initial !important;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 15px 0;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.03);
}

.login-footer a,
.entry-footer a {
  outline: 0;
}

.login-footer img,
.entry-footer img {
  margin: 5px;
  height: 25px;
}

.login-footer .comodo-logo,
.entry-footer .comodo-logo {
  margin-top: -5px;
  height: 45px;
}

.login-paragraph,
.enrty-paragraph {
  margin-bottom: 20px;
}

.login-footer .security-metrics-logo,
.entry-footer .security-metrics-logo {
  margin-left: 10px;
  height: 30px;
}

.login .secure-health-exchange-logo,
.entry-page .secure-health-exchange-logo {
  width: 75%;
  margin-bottom: 30px;
}

.copyright-text {
  float: right;
  margin: 17px 20px 0 -155px;
  font-size: 12px;
  color: #555;
  font-style: italic;
  top: 10px;
  right: 20px;
}

.contactus {
  font-size: 0.8em;
  line-height: 1.5;
  padding-top: 20px;
  color: #555;
  font-style: italic;
}

.btn-fixed-width {
  width: 150px;
}

.compliance-page {
  position: relative;
  margin: 0 auto;
  padding: 50px 30px;
  max-width: 1000px;
  color: #555;
}

.compliance-page h4 {
  color: #333;
}

.compliance-page .flex {
  margin-bottom: 20px;
}

.compliance-page .description {
  flex: 1;
}

.compliance-page .logo-img {
  margin: 10px 40px 0 0;
  width: 120px;
}

.compliance-page .comodo {
  margin-top: -8px;
  transform: scale(1.1);
}

/* Subscription */

button.billing-settings {
  height: fit-content;
  padding: 10px;
  background: #2985c9;
  color: white;
  border: none;
}

.packages a.btn {
  width: 100%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card:hover {
  box-shadow: 0 8px 15px 5px rgba(0, 0, 0, 0.2);
}

span.subdesc:empty:before {
  content: '\200b';
}

@media (min-width: 768px) {
  .equal {
    display: flex;
    flex-wrap: wrap;
  }
}

/* Settings Styles */

.react-tel-input .form-control {
  border-radius: 0px !important;
}

.user-org-details {
  position: relative;
  padding: 0 30px 0 15px;
  margin: 0 -15px 15px;
  border-style: solid;
  margin-right: 0;
  margin-left: 0;
  background-color: #fff;
  border-color: #ddd;
  border-width: 1px;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
}

.maintenance {
  background-color: white;
}

.maintenance-logo {
  width: 700px;
  max-width: 100%;
}

/* MHR STYLES */

.mhrpage .login .center-v,
.mhrpage h3 {
  color: #000;
}

.mhr-light {
  position: relative;
  background: #35424a;
  padding: 0.9em 1em;
  text-decoration: none;
  color: #fff;
  width: 100%;
  font-weight: 800;
  text-align: center;
}

.mhr-cc .table {
  border: 1px solid #eeeeee;
  border-top: none;
  padding: 10px;
  width: 100%;
  display: table;
  border-collapse: separate;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background: #f8f9fa;
  text-align: center;
}

.mhr-cc .table .name {
  font-size: x-large;
  vertical-align: top;
  font-weight: 500;
  text-transform: capitalize;
  color: #005072;
  line-height: 35px;
}

.mhr-cc .details {
  font-size: small;
  padding-top: 15px;
  line-height: 25px;
  font-family: monospace;
}

.mhr-cc .details .gender {
  text-transform: capitalize;
}

@media (max-width: 767.98px) {
  .compliance-page .flex {
    flex-direction: column;
  }
}

.menu-btn-link {
  width: 100%;
  color: #333333 !important;
  background: transparent;
  text-align: left !important;
  margin: 0 !important;
  border-radius: 0 !important;
  justify-content: left !important;
  font-size: 12px !important;
  font-weight: normal !important;
  padding: 12px 15px !important;
}

.topbar {
  position: relative;
  margin-bottom: 0;
  width: 100%;
  height: 60px;
  padding: 5px;
  background-color: #2985c9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 99;
}

.topbar button#unread-filter,
.topbar button#dropdown-menu {
  background: transparent;
  color: white;
  text-decoration: none;
}

.topbar .secure-health-logo {
  height: 40px;
  margin: 5px;
}

.topbar > ul {
  display: inline-block;
  padding: 0;
  margin: 0;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  vertical-align: middle;
  transition: max-width 0.5s ease;
}

.topbar li {
  display: inline-block;
  list-style: none;
}

.topbar .dropdown li {
  display: flex;
}

ul.dropdown-menu a {
  width: 100%;
  text-align: left;
}

.topbar > ul > li,
.topbar > ul > li > a,
.topbar .help-list-item {
  height: 100%;
}

.topbar a,
.topbar .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 12px;
  min-width: 50px;
  color: #fff;
  outline: 0;
  text-align: center;
}

.topbar a > [class^='ion'] {
  display: inline-block;
  transform: scale(1.6);
}

.topbar a:hover,
.topbar a:active,
.topbar .btn:hover,
.topbar .btn:active {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  border-radius: 5px;
}

/* Compose circle used for mobile only */
.compose-circle {
  display: none;
}

ul.dropdown-menu.dropdown-menu-right {
  margin: 2px 5px;
}

.topbar .btn,
.compose-btn {
  border: 0;
  margin: 0 5px 0 0;
  padding: 12px;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  outline: 0 !important;
}

.upgrade-btn {
  background-color: #fbf06c !important;
  color: black !important;
}

.topbar .btn-success,
.topbar .compose-btn {
  background-color: #58bd45;
}

.topbar .fa-bars {
  transform: scale(1.2);
}

.topbar .btn-primary {
  background-color: #00c4ff;
}

.topbar .btn-primary:hover,
.topbar .btn-primary:active {
  background-color: color(#00c4ff tint(10%));
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
}

.topbar .btn-success:hover,
.topbar .btn-success:active,
.topbar .compose-btn:hover,
.topbar .compose-btn:active {
  background-color: color(#58bd45 tint(10%));
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.topbar .btn-success:active,
.topbar .btn-primary:active,
.topbar .compose-btn:active {
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.3);
}

[data-reach-menu-button] {
  border-color: transparent;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

[data-reach-menu-list] {
  right: 0;
}

[data-reach-menu] {
  z-index: 100;
}

.topbar-search {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 10px;
  font-weight: 300;
  padding: 0 8px;
  font-size: 15px;
  border-radius: 5px;
  color: #fff;
}

.topbar-search > button,
.topbar-search > input {
  color: #fff;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: 0;
}

.topbar-search > button {
  display: block;
  padding: 8px 6px;
  height: 38px;
  width: 30px;
  text-align: center;
}

.topbar-search > input {
  width: 300px;
  color: #fff;
  opacity: 1;
}

.topbar-search > input::-ms-clear {
  display: none;
}

.topbar-search .icon-delete {
  height: 22px;
  width: 20px;
  vertical-align: top;
}

.topbar .exit-search {
  display: none;
  overflow: hidden;
  width: 0;
}

.topbar .exit-search i {
  display: inline-block;
  transform: scale(1.5);
}

.topbar-search .fa-search {
  font-size: 18px;
}

.topbar-search ::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.65;
}

.topbar-search :-moz-placeholder {
  color: #fff;
  opacity: 0.65;
}

.topbar-search :-ms-input-placeholder {
  color: #fff;
  opacity: 0.65;
}

.topbar-search ::-moz-placeholder {
  color: #fff;
  opacity: 0.65;
}

.topbar-search ::placeholder {
  color: #fff;
  opacity: 0.65;
}

#bs-menu {
  display: flex;
  float: right;
  padding: 0;
  height: 100%;
  font-size: 18px;
}

#bs-menu > ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

#bs-menu > ul > li,
.topbar > ul > li {
  margin: 0 1px;
}

.page-title {
  display: inline-block;
  color: #fff;
  font-weight: 300;
  font-size: 20px;
  max-width: 100%;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
  transition: max-width 0.5s ease;
}

.main-content {
  display: flex;
  height: 100%;
  overflow-y: auto;
}

/* FOLLOWING SMACSS RULES (see smacss.com) use .l- for css layouts */

.l-list-view,
.l-details-view {
  position: relative;
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.l-list-view {
  padding: 20px;
  border-right: 1px solid #ddd;
  width: 35%;
  overflow-y: auto;
}

.l-list-view .fa-spin {
  font-size: 150%;
  vertical-align: top;
}

.l-full-view,
.l-details-view {
  padding: 20px;
  overflow-y: auto;
}

.l-full-view {
  height: calc(100% - 60px);
  width: 100%;
}

.l-full-view > :first-child,
.l-details-view > :first-child {
  margin-top: 0;
}

.l-details-view {
  width: 65%;
  overflow-x: hidden;
}

.l-fullscreen-scroll {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.l-compose .input-group {
  margin-bottom: 12px;
}

.heading-top {
  margin-top: 0;
  vertical-align: middle;
}

.heading-top.flexbox {
  margin-bottom: 15px;
  align-items: center;
}

.heading-top h3 {
  vertical-align: middle;
  margin: 0;
}

.form-page form {
  margin: 30px auto;
  padding: 30px;
  max-width: 1000px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
}

.navbar-brand {
  padding: 5px;
}

.navbar-brand:focus,
.navbar-brand:active {
  outline: none;
}

.navbar-inverse {
  background-color: #fff;
}

.navbar-inverse .navbar-text {
  color: #2985c9;
}

.compliance-logo {
  background: url('../img/compliance-logos.png') center no-repeat;
  width: 100%;
  height: 60px;
  background-position: left;
}

.modal-dialog .tab-pane {
  padding: 20px 5px;
  font-size: 13px;
}

.selected-value {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-size: 100%;
  margin-right: 5px;
  border-radius: 50%;
}

.secure-mail-send-icon {
  margin: -4px 2px 0 -3px;
  height: 20px;
}

#to-list .k-item {
  line-height: 1em;
  min-width: 300px;
}

.send-button-options {
  display: inline-block;
  margin-left: 10px;
  line-height: 1;
  vertical-align: middle;
}

.send-button-options input[type='radio'] {
  margin: -2px 10px 0 0;
}

.sendAsButton {
  float: left;
}

/* Material Theme padding adjustment */

.k-material #to-list .k-item,
.k-material #to-list .k-item.k-state-hover,
.k-materialblack #to-list .k-item,
.k-materialblack #to-list .k-item.k-state-hover {
  padding-left: 5px;
  border-left: 0;
}

#to-list .k-item > span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  margin: 20px 10px 10px 5px;
}

#to-list .k-item > span:first-child {
  -moz-box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.3);
  margin: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

#to-list h3 {
  font-size: 1.2em;
  font-weight: normal;
  margin: 0 0 1px;
  padding: 0;
}

#to-list p {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
}

div.k-dropzone {
  border: 1px solid #c5c5c5;
}

div.k-dropzone em {
  visibility: visible;
}

.attachmentDropZone li span > span {
  word-wrap: break-word;
  display: inline-block;
  word-break: break-word;
  word-break: break-all;
}

button.k-upload-selected {
  visibility: hidden;
}

.panel-info {
  border-color: #8e98a3;
  border-radius: 3px;
}

.panel-info > .panel-heading {
  color: #fff;
  background-color: #8e98a3;
  border-color: #8e98a3;
}

.message-header {
  display: flex;
  margin: 0 10px 15px;
}

.message-header [class^='ion'] {
  display: inline-block;
  margin-right: 5px;
  transform: scale(1.1);
}

.message-header-actions > span {
  position: relative;
}

.message-header-actions [class^='ion'] {
  margin-right: 0;
}

.message-thread-title {
  word-break: break-word;
  margin: 0;
  padding-right: 10px;
  color: #333;
  flex: 1;
  align-self: center;
}

ul.encounter {
  padding: 0px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

li.encounter-item {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  display: block;
  border-top: 1px solid #ddd;
  padding: 10px;
  font-size: 13px;
}

ul.encounter:last-child {
  border-bottom: 1px solid #ddd;
}

.message-panel {
  color: #555;
  font-size: 14px;
  border-top: 1px solid #ddd;
}

.message-panel .panel-heading,
.message-panel .panel-body {
  padding: 15px 10px;
}

.message-panel .panel-heading {
  position: relative;
  background-color: #fff;
}

.message-panel .panel-heading:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.message-panel .message-summary {
  margin: 8px 0 0;
  max-height: 30px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease, max-height 0.3s ease;
}

.message-panel.open .message-summary {
  margin: 0;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: none;
}

.message-panel .panel-body {
  display: none;
  padding: 0;
}

.message-panel.open .panel-body {
  display: block;
  padding: 0 10px 15px;
  animation: fade-in 0.5s ease;
}

.message-panel-sender {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #333;
}

.message-panel-recipients {
  font-size: 90%;
}

.message-panel .panel-body {
  padding-top: 0;
}

.message-panel-options {
  float: right;
  display: inline-flex;
  align-items: center;
}

.message-panel-options [class^='ion'],
.message-panel-options [class^='fa'],
.message-panel-options svg {
  display: inline-block;
  margin-right: 8px;
}

.message-panel-options [class^='ion'] {
  transform: scale(1.2);
}

.message-panel-options .dropdown {
  margin: 0;
  vertical-align: top;
}

.message-panel-options .dropdown-toggle {
  margin-right: -15px;
  padding: 0 15px;
  background-color: transparent;
  border: 0;
  outline: 0;
}

.message-panel-options .open .dropdown-toggle {
  box-shadow: none;
}

.message-panel-options .fa-paperclip {
  margin-right: 8px;
  font-size: 12px;
}

.message-panel .k-editor {
  margin-bottom: 10px;
  line-height: 1.5;
}

.ellipsis-btn {
  margin: 8px 0;
  padding: 3px 10px;
  border-radius: 3px;
  background: #f4f5f6;
}

.ellipsis-btn:hover {
  background: #ddd;
}

.message-unsecure {
  background-color: #f4f5f6;
  border: 3px;
  padding: 10px 15px 20px;
}

.message-unsecure .k-widget {
  background-color: transparent;
}

.message-unsecure-header {
  margin-bottom: 10px;
}

.message-actions {
  display: inline-block;
  font-size: 14px;
  padding: 5px 10px;
  margin-bottom: 20px;
}

.message-actions .btn-link + .btn-link {
  margin-left: 20px;
}

.message-actions .btn-link [class*='fa-'] {
  display: inline-block;
  margin-right: 5px;
  text-align: center;
}

.attachment-compose .panel {
  margin-bottom: 0;
}

.attachment-compose .panel-body {
  padding: 0;
}

.Select-value .invalid {
  color: red;
  text-decoration: line-through;
}

.Select-value .Select-value-icon,
.Select-value .Select-value-label {
  display: table-cell;
  color: #555;
}

.is-disabled .Select-value .Select-value-icon,
.is-disabled .Select-value .Select-value-label {
  color: #aaa;
}

.attachmentDropZone {
  width: auto;
  height: 110px;
  min-height: 100px;
  border: 0;
  padding: 5px;
  overflow: auto;
}

.attachmentDropZoneActive {
  border: 1px dashed blue;
  background-color: lightblue;
}

.secure-body .ql-editor {
  min-height: 120px;
  height: 250px;
  resize: vertical;
}

.address2 {
  margin-top: 5px;
}

.invite-email {
  box-sizing: border-box;
}

.tnc .rform {
  max-height: 500px;
  overflow: auto;
}

.tnc-accept-checkbox {
  display: inline-block;
  text-align: left;
  margin-right: 30px;
  margin-top: 16px;
}

.tnc-accept-checkbox label {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.confirm-checkbox {
  margin-right: 10px !important;
  margin-left: -23px !important;
}

.confirm-label {
  margin-left: 18px !important;
}

.confirm-row {
  margin-left: 10px !important;
}

.list-group-item > .badge {
  float: right;
}

.row {
  margin-right: 0 !important;
}

td span[class*='fa-'] {
  font-size: 15px;
  margin-left: 5px;
}

input#search {
  float: right;
}

.Select-control {
  border-radius: 0;
  height: 39px;
}

span.invite-button {
  margin-top: -3px;
  padding: 4px 10px;
  border-radius: 4px;
}

.Select-aria-only {
  display: none;
}

.validation-summary {
  height: 59px;
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 0;
  padding: 20px 10px;
}

.change-email-box {
  width: -moz-calc(100% - 100px);
  width: -webkit-calc(100% - 100px);
  width: calc(100% - 100px);
  float: left;
}

.pendingUsers {
  overflow: auto;
}

.pendingUsers .btn {
  padding: 4px 8px !important;
  float: right;
  margin-left: 5px;
}

.pendingUsers .userName {
  font-size: 15px;
  line-height: 30px;
}

input.thread-selector {
  height: 20px;
  width: 20px;
}

.Select-menu-outer {
  z-index: 3;
}

.ui-input-required {
  border: 1px solid indianred;
}

.modal.fade.tnc.in {
  overflow-y: auto;
}

.notification-dialog.modal {
  overflow-y: auto;
}

/* forms */

.jotform-modal .dialog-backdrop {
  padding: 0;
}

.form-list {
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.p-form-compose {
  display: flex;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 8px;
  gap: 12px;
  color: #6f6f6f;
  align-items: center;
  margin-bottom: 12px;
}

.p-form-compose svg {
  cursor: pointer;
}

.p-form-compose .fa-file-waveform {
  cursor: default;
  height: 32px;
  color: #2985c9;
}

.p-form {
  display: flex;
  margin: 8px 8px 0 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px;
}

.p-form svg {
  height: 32px;
  margin: 4px 8px 4px 4px;
  color: #2985c9;
}

.p-form button {
  border: none;
  padding: 4px 12px;
}

#legacy [class*='fa-'] {
  font-size: 1.4em;
  margin-right: 5px;
}

#legacy a,
#ie10 a {
  color: #b94442;
  font-weight: bold;
  font-style: italic;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebcccc;
}

.alert-message {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.success-check {
  font-size: 5rem;
  color: white;
  float: left;
  margin: 1rem 2rem 2rem 0;
}

.input-group.send-group,
.compose .alert {
  float: left;
  line-height: 26px;
  width: 100%;
  margin-bottom: 12px;
}

.compose .alert {
  padding: 8px 12px;
}

.search-group button {
  min-width: 44px;
}

#searchclear {
  position: relative;
  float: left;
  margin: 12px 0 0 -20px;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
  z-index: 3;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.thread {
  position: relative;
}

.thread-block {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  font-size: 13px;
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-bottom: 1px solid #ddd;
  border-radius: 2px;
  overflow: hidden;
}

.thread-block.info,
.thread-block.info:hover {
  background-color: rgba(0, 130, 255, 0.1);
  border: 1px solid #ddd;
}

.thread-block:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.thread-block.active {
  background-color: rgba(0, 0, 0, 0.07);
}

a.thread-block,
.thread-block a {
  outline: 0;
  text-decoration: none;
  color: #333;
}

.thread-block .contact-type {
  display: inline-block;
  margin-right: 10px;
  color: #555;
  transform: scale(1.15);
}

.contact-type.ion-person {
  transform: scale(1.3);
}

.contact-type.meddialog-icon {
  width: 16px;
  margin-left: -5px;
}

.thread-block .invite-status {
  display: inline-block;
  margin-right: 0;
  color: #ff9000;
  transform: scale(1.2);
}

.thread-status {
  display: flex;
  color: rgba(0, 0, 0, 0.5);
  align-items: center;
}

.thread-status [class*='fa-'],
.thread-status [class^='ion'] {
  display: inline-flex;
  margin-left: 6px;
}

.category {
  display: inline-flex;
  margin-left: 6px;
  font-size: 10px;
  height: 13px;
  width: 13px;
  padding: 0 2px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  line-height: 1.1;
  color: #fff;
}

.category-label {
  margin: 4px 8px 0 0;
  padding: 2px 6px;
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
}

.thread-middle {
  width: 100%;
}

.thread-senders {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.thread-right {
  text-align: right;
  margin-left: 10px;
  float: right;
  white-space: nowrap;
}

.thread-senders,
.thread-right {
  height: 20px;
}

.thread-right > span,
.thread-right > button {
  display: inline-block;
  background-color: transparent;
  border: 0;
  outline: 0;
  vertical-align: middle;
}

.thread-subject {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thread-label {
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  padding: 2px 4px;
  margin-right: 5px;
  border-radius: 2px;
  color: #fff;
  background-color: #777;
}

.thread-label.bg-greenish {
  background-color: #92c935;
}

.bold .thread-subject {
  font-weight: normal;
}

.thread .row {
  height: 16px;
}

.thread-actions-icon {
  color: rgba(0, 0, 0, 0.6);
}

.thread-actions-icon:hover {
  color: #2985c9;
}

.thread-actions-icon {
  display: inline-block;
  max-width: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  text-align: center;
  transform: scale(1.15);
  overflow: hidden;
  transition: max-width 0.2s ease, margin 0.2s ease, opacity 0.2s ease;
}

.thread-block:hover .thread-actions-icon,
.thread-block.info .thread-actions-icon {
  max-width: 14px;
  padding: 0 2px;
  margin: 0 0 0 5px;
  opacity: 1;
  overflow: hidden;
  transform: scale(1.15);
  transition: max-width 0.2s ease, margin 0.2s ease, opacity 0.2s ease;
}

.draft-actions {
  margin-left: 6px;
}

.draft-action-btn {
  display: inline-block;
  transform: scale(1.2);
  margin: -5px 0 0 6px;
  padding: 0 6px;
  vertical-align: middle;
}

.l-profile-list {
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  overflow-y: hidden;
}

.l-profile-list .profiles {
  flex: 1;
  padding: 20px;
  overflow: auto;
}

.profile-layout {
  position: relative;
  width: 100%;
}

.profile-head {
  position: relative;
  background-color: #999;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
}

.profile-head h4,
.profile-head h5 {
  margin: 10px 0 5px;
  font-size: 19px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
}

.profile-head .bg-tint-20 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 245px;
  padding: 20px 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.profile-head .avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 120px;
  border-radius: 20%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 70px;
  font-weight: 300;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.profile-block {
  margin: 0 auto;
  width: 220px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.profile-block:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.profile-block a {
  display: inline-block;
  outline: 0;
  padding: 15px 10px;
  width: 100%;
  text-decoration: none;
}

.profile-block > a > [class*='fa-'],
.profile-block > a > [class^='ion'] {
  display: inline-block;
  float: right;
}

.roleSelector .btn-group {
  padding: 10px 0;
}

.roleSelector label.btn.btn-primary {
  padding: 5px 10px;
}

.roleSelector-label {
  line-height: 50px;
}

.dateOfBirth-label {
  line-height: 30px;
}

#refer-dialog .col-md-4 {
  line-height: 45px;
}

#refer-dialog .rolesRadio {
  line-height: 45px;
}

ul.org-list {
  max-height: 200px;
  overflow: auto;
}

.orgSuggestions {
  margin: 5px 0 !important;
}

.orgSuggestions .header {
  color: #fff;
  background-color: #8e98a3;
  border-color: #8e98a3;
  padding: 5px;
}

.orgSuggestions li {
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding: 2px;
}

.ql-disabled .ql-editor {
  padding: 0;
}

.ql-picker-label {
  margin-right: 25px;
}

.message-panel .ql-container.ql-snow {
  border: 0;
}

/*************************************
 CHAT MODULE
*************************************/
.chat-list {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-module {
  padding: 0;
  overflow-y: hidden;
}

.chat-bar {
  background-color: #fff;
  border-top: 1px solid #eee;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.07);
  z-index: 1;
}

.chat-header {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.07);
  z-index: 1;
}

.chat-inline-list {
  display: inline-block;
  color: #777;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.chat-inline-list .list-element {
  display: inline;
  padding: 0 8px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 13px;
}

.chat-inline-list .list-element:first-child {
  padding-left: 0;
}

.chat-inline-list .list-element:last-child {
  border-right: 0;
}

.chat-inline-list .list-element:first-child [class*='fa-'] {
  margin: 0 2px 0 8px;
  transform: scale(1.3);
}

.secondTotal {
  margin: 0;
}

.list-group-item.member {
  padding: 10px 5px;
  background-color: transparent;
  border: 0;
  border-top: 1px solid #ddd;
  font-weight: normal;
  color: #777;
}

.list-group-item.member .name {
  max-width: calc(100% - 200px);
  display: inline-block;
  text-overflow: ellipsis;
}

.list-group-item.member:last-child {
  border-bottom: 1px solid #ddd;
}

.list-group-item.member a:hover .fa-remove {
  color: #dd4040;
}

.list-group-item.member .btn-no-style {
  width: 20px;
  padding: 0 3px;
}

.list-group-inline-validation {
  margin: -6px -10px 0 0;
}

.list-group-inline-validation > span,
.list-group-inline-validation > .btn {
  margin-left: 5px;
  border-radius: 2px;
}

.chat-header .btn-edit {
  display: inline-block;
  background-color: #2985c9;
  margin: 0 0 -1px 4px;
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 13px;
  vertical-align: middle;
}

.chat-header .btn-edit .fa-ellipsis-h {
  padding: 4px;
}

.chat-header .btn-edit .fa-pencil {
  padding: 1px 5px;
}

.chat-title {
  margin: 0 0 10px;
}

.chat-channels .thread-block {
  padding: 0;
}

.chat-channels .thread-block > a {
  display: inline-block;
  padding: 10px;
  width: 100%;
}

.chat-messages {
  flex: 1;
  padding: 10px 0;
  font-size: 14px;
  line-height: 1.5;
  color: #555;
  overflow-y: auto;
}

.chat-message {
  position: relative;
  padding: 8px 25px 8px 65px;
}

.chat-message:hover,
.chat-channels .thread-block:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.chat-channels .thread-block.info:hover {
  background-color: rgba(0, 130, 255, 0.1);
}

.chat-avatar {
  font-family: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
  position: absolute;
  left: 15px;
  height: 35px;
  width: 35px;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  line-height: 2;
  vertical-align: middle;
}

.chat-from {
  line-height: 1.2;
}

.chat-image {
  position: relative;
  display: block;
  margin: 8px 8px 0 0;
  padding: 8px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  max-width: 280px;
}

.chat-image a {
  margin: 0;
  padding: 0;
  border: 0;
  color: #555;
  text-decoration: none;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.chat-image img {
  margin: 0;
  cursor: pointer;
}

.chat-image-disabled {
  background-color: #ddd;
}

.chat-filename {
  display: inline-block;
  color: #555;
  margin-left: 8px;
  width: calc(100% - 35px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

img + .chat-filename {
  width: calc(100% - 65px);
}

.chat-image-comment {
  margin-top: 4px;
}

.chat-header,
.chat-bar {
  padding: 15px;
}

.chat-bar .btn {
  min-width: 40px;
}

.chat-file-icon,
.chat-image > svg {
  font-size: 26px;
  vertical-align: middle;
}

.chat-bar .input-group-btn:first-child .btn {
  border-radius: 3px 0 0 3px;
}

.chat-bar .input-group-btn:last-child .btn {
  border-radius: 0 3px 3px 0;
}

.chat-bar .ion-android-send::before {
  margin-right: -2px;
  transform: scale(1.2);
}

.chat-message.local {
  opacity: 0.75;
}

.drop-zone {
  border: 1px dashed rgba(0, 0, 0, 0.2);
  color: #777;
  font-size: 14px;
  padding: 20px 10px;
  text-align: center;
  width: 100%;
}

.drop-zone:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.drop-zone [class*='fa-'] {
  margin: 0 10px 0 0;
  transform: scale(1.4);
}

.drop-zone-thumbnail {
  display: inline-block;
  width: calc(50% - 20px);
  margin: 0 10px;
}

.drop-zone-image {
  margin: 0 auto 10px;
  max-height: 100px;
  max-width: 100%;
}

.drop-zone-file-preview {
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drop-zone-label {
  word-break: break-all;
}

.chat-divider {
  margin: 10px 0;
}

.chat-divider hr {
  margin: 0;
  border-color: rgba(220, 60, 60, 0.35);
}

.chat-divider-label {
  float: right;
  padding: 0 5px;
  font-size: 12px;
  font-weight: bold;
  margin: -10px 10px 0;
  background-color: #fff;
  color: rgba(220, 60, 60, 0.6);
}

.image-viewer-img {
  max-width: 100%;
}

.video-viewer-video {
  max-width: 100%;
  max-height: 90vh;
}

#fpModal input {
  color: #6f6f6f;
  border: 1px solid #ccc;
}

#fpModal .modal-title {
  color: #555;
}

.pricing-table {
  width: 100%;
  margin: 30px 0 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.pricing-table thead th {
  position: relative;
  vertical-align: top;
  padding-top: 20px;
  padding-bottom: 55px;
  min-width: 150px;
}

.pricing-table thead th h4 {
  margin-top: 0;
}

.pricing-table th {
  background-color: #f9fafa;
  font-weight: normal;
}

.pricing-table th .btn {
  position: absolute;
  bottom: 15px;
}

.pricing-table th,
.pricing-table td {
  padding: 10px 15px;
  border: 1px solid #e5e5e5;
}

.pricing-table .price {
  font-size: 3.33333em;
  color: #30373b;
  white-space: nowrap;
}

.pricing-table .price sup {
  margin-top: 0.75em;
  top: -2em;
  font-size: 0.3em;
}

.pricing-table h5 {
  display: block;
  margin: 10px 0;
  color: #30373b;
  font-size: 0.8125em;
  line-height: 1.38;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.pricing-table i {
  color: #7ab55c;
}

.pricing-table .fa-times {
  color: #dd4040;
}

.pricing-table .feature-value {
  font-size: 1.5em;
}

.fade-text,
.fade-text [class*='fa-'],
.price.fade-text {
  color: #dddddd;
}

/*************************************
 BOOTSTRAP OVERRIDES
*************************************/

.tooltip,
.popover {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.btn-primary {
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #2985c9;
  box-shadow: none;
}

.btn-primary[disabled]:hover {
  background-color: #2985c9;
}

.btn-primary:hover {
  background-color: color(#2985c9 shade(25%));
}

.btn-link {
  font-family: inherit;
  color: #2985c9;
}

.btn-link:hover {
  color: color(#2985c9 shade(25%));
  cursor: pointer;
}

.btn-link:disabled {
  color: #999999;
  text-decoration: none;
  cursor: default;
  border: none;
}

button.close {
  outline: 0;
}

.letter,
.letter blockquote {
  font-size: 14px;
}

.letter .modal-body {
  padding: 60px;
  line-height: 1.5;
}

.letter .modal-header,
.modal-header-extra {
  padding: 20px 20px 20px 30px;
}

@media (max-width: 575.98px) {
  .letter .modal-body {
    padding: 30px;
  }
}

.signature {
  display: inline-block;
  font-size: 90%;
  border-top: 1px solid #999;
  width: calc(33.33% - 20px);
  vertical-align: top;
  margin: 40px 0 0;
  padding-top: 10px;
}

/* TRAINING CSS */

.ut > div {
  line-height: 35px;
}

.w-ans {
  margin-left: 20px;
}

.w-q {
  padding: 5px;
}

.w-q,
.w-a {
  background: #fff0f07d;
}

.w-ans div {
  padding: 5px;
}

.course-viewer {
  height: 100%;
  height: -webkit-calc(100% - 28px);
  height: -moz-calc(100% - 28px);
  height: calc(100% - 28px);
  overflow: auto;
}

.quiz .q-p {
  height: -webkit-calc(100% - 28px);
  height: -moz-calc(100% - 28px);
  height: calc(100% - 28px);
  overflow: auto;
  padding: 10px;
}

.q-p input,
.q-p span {
  line-height: 25px;
  margin: 0 5px;
}

.q-p .ans span {
  margin-left: 20px;
}

.q-p div {
  float: none;
}

.q-p .ans {
  margin-top: 10px;
}

.start-course {
  padding: 4px 12px;

  border-radius: 5px;
}

.courses {
  overflow: auto;
}

.courses a,
.courses a:hover {
  text-decoration: none;
  color: #555;
}

.courses a > div {
  margin: 10px;
  padding: 15px;
  overflow: auto;
}

.courses .panel-footer,
.quiz .panel-footer,
.module .panel-footer {
  padding: 5px 15px;
}

.well.completed,
.well.inprogress {
  background-color: #429b46;
  border-color: #d2eedb;
  color: white !important;
}

.well.inprogress {
  background-color: #2985c9 !important;
}

.courses .well.selected h4,
.courses .well.selected svg {
  color: #555 !important;
}

.well.completed h4,
.well.completed svg,
.well.inprogress h4,
.well.inprogress svg,
.well.selected.completed h4,
.well.selected.completed svg,
.well.selected.inprogress h4,
.well.selected.inprogress svg {
  color: white !important;
}

.courses .well.selected {
  background-color: rgba(0, 130, 255, 0.1) !important;
  color: #555 !important;
  border: 2px solid #ddd;
}

.courses .well.completed.selected {
  background-color: #429b46 !important;
  border-color: #d2eedb;
  color: white !important;
}

.courses .well.inprogress.selected {
  background-color: #1d7fc7 !important;
  border-color: #d2eedb;
  color: white !important;
}

/***********************************
 FORM OVERRIDES
***********************************/

/* START Overrides for react-date-time */
.date-pik-desktop {
  min-width: 250px;
  width: 100%;
  height: 100%;
  display: flex;
}

.date-pik-mobile {
  width: 100%;
  height: 100%;
  padding: 0px 10px;
}

.dob-picker {
  position: relative;
  height: 100%;
}

.dob-picker select {
  height: 100%;
  padding: 0px 10px;
  flex: 1;
}

.dob-picker select:not(:last-child) {
  margin-right: 5px;
}

.rdtPrev,
.rdtNext,
.rdtSwitch {
  color: #2985c9;
}

.dob-picker .form-control[readonly] {
  color: #555;
  background-color: #fff;
  cursor: default;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.edit-field {
  display: inline-block;
  width: auto;
  margin-left: 20px;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 3px;
  text-align: center;
}

.edit-field .rdt,
.edit-field .dob-picker {
  display: inline-block;
}

.edit-field input {
  cursor: pointer;
  padding: 0 4px;
  height: auto;
  width: 100px;
  color: white;
  background-color: transparent;
  box-shadow: none !important;
  border: 1px solid transparent !important;
  outline: 0;
  text-align: center;
}

.edit-field button {
  display: inline-block;
  margin-left: -1px;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  width: 30px;
  border: 1px solid transparent;
  outline: 0;
}

.edit-field:hover button,
.edit-field.active button {
  background-color: rgba(255, 255, 255, 0.2);
}

.edit-field:hover,
.edit-field.active {
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.6);
}

.edit-field button:hover {
  color: rgba(0, 0, 0, 0.6);
}

.edit-field.disabled {
  border: 1px solid transparent;
  margin-left: 0;
  box-shadow: none;
}

.edit-field.disabled input {
  cursor: default;
}

.form-control {
  box-shadow: none;
}

.form-control .form-control {
  border: 0;
  outline: 0;
  box-shadow: none;
  background: transparent;
}

.form-control input.form-control:focus {
  outline: 0;
}

.center-align {
  margin: 0 auto;
}

/* END Overrides for material UI */

.form-control[disabled],
.form-control.disabled,
.form-control[readonly],
.form-control.readonly,
fieldset[disabled] .form-control {
  color: #aaa;
  background-color: #f9f9f9;
  cursor: not-allowed;
  outline: 0 !important;
  border-color: #ccc;
  box-shadow: none;
}

.form-control.radio .radio-inline,
.form-control.checkbox .checkbox-inline,
.form-control label {
  padding-top: 3px;
}

.form-control .inline-label {
  display: inline-block;
  padding-top: 3px;
  vertical-align: top;
}

.form-control .radio-inline + .radio-inline,
.form-control .checkbox-inline + .checkbox-inline,
.form-control .inline-label + .radio-inline,
.form-control .inline-label + .checkbox-inline,
.form-control .inline-label + .btn-link,
.form-control .btn-link + .btn-link,
.form-control label + .btn-link {
  margin-left: 15px;
}

.form-control .btn-link {
  vertical-align: middle;
  font-size: 12px;
  margin-bottom: 0;
}

.form-control.upload {
  height: auto;
}

.form-control.upload label {
  position: relative;
  max-width: calc(50% - 15px);
  top: 3px;
  margin: 0 15px 0 0;
  padding: 1px 0 0;
}

.checkbox-inline.title-checkbox {
  padding-top: 3px;
}

.form-horizontal .labels-left .control-label {
  text-align: left;
}

.form-horizontal .labels-right .control-label {
  text-align: right;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
  height: auto;
  min-height: 39px;
}

textarea.form-control {
  resize: vertical;
}

.striped {
  background-color: #fff;
}

.striped:nth-child(even) {
  background-color: #f5f7f9;
}

/***********************************
 QUILL OVERRIDES
***********************************/

.quill {
  word-break: break-word;
}

.ql-container {
  font-size: inherit;
}

.ql-editor {
  line-height: 1.4;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  line-height: 1.4;
}

.ql-snow .ql-editor h4 {
  font-size: 1.072em;
}

/***********************************
 MEDIA QUERIES
***********************************/

/* For Desktop */
@media (max-width: 1199.98px) {
  .side-panel {
    width: 190px;
  }

  .main-panel {
    left: 190px;
  }

  .l-list-view {
    width: 310px;
  }

  .l-details-view {
    width: calc(100% - 310px);
  }

  .topbar-search > input {
    width: 200px;
  }

  .topbar-search > input[type='text']::-ms-clear {
    display: none;
  }

  .navbar-label {
    display: none;
  }
}

/* For Mobile devices */
@media (max-width: 991.98px) {
  .l-fullscreen-scroll,
  .l-full-view,
  .l-details-view,
  .l-list-view,
  .l-compose,
  .side-panel-scroll {
    -webkit-overflow-scrolling: auto;

    /* All -webkit-overflow-scrolling must be auto to work correctly */
  }

  .download-all {
    display: none;
  }

  .empty-trash {
    margin-right: 5px;
    margin-top: 5px;
  }

  .topbar-search {
    margin: 0px;
    padding: 0px;
  }

  .side-panel {
    width: 260px;
    transform: translateX(-104%);
    box-shadow: 2px 0 15px rgba(0, 0, 0, 0.3);
    z-index: 999;
    transition: transform 0.3s ease;
  }

  .main-panel {
    left: 0;
  }

  .page-title {
    font-size: 17px;
  }

  .message-panel .panel-heading,
  .message-panel.open .panel-body,
  .message-panel .message-header {
    padding: 15px;
  }

  .message-header {
    display: flex;
    margin: 15px;
    flex-direction: column;
  }

  .message-header-actions .btn-link {
    padding: 0;
    margin: 10px 10px 0 0;
  }

  .message-thread-title {
    font-size: 18px;
    line-height: 1.3;
    padding-right: 5px;
  }

  .message-actions {
    display: flex;
    width: 100%;
    padding: 0 15px;
    margin-bottom: 90px;
  }

  .message-actions a.btn-link {
    flex: 1;
    border: 1px solid #ddd;
    font-size: 13px;
    padding: 10px;
    margin: 3px !important;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .message-actions .btn-link .svg-inline--fa {
    width: 100%;
    display: block;
    font-size: 110%;
  }

  .topbar .btn,
  .compose-btn {
    margin: 0;
  }

  .l-list-view,
  .l-details-view {
    width: 100%;
    padding: 0;
  }

  .l-full-view.l-compose {
    padding: 10px;
  }

  .l-compose .input-group {
    margin-bottom: 10px;
  }

  .l-compose [class^='col'] {
    padding: 0;
  }

  .l-compose .row {
    padding-left: 15px;
  }

  .attachmentDropZone {
    height: auto;
  }

  .side-panel-tint {
    position: absolute;
    background-color: rgba(60, 60, 60, 0.65);
    width: 0;
    height: 0;
    opacity: 0;
    z-index: 100;
    transition: opacity 0.2s linear, height 0s 0.3s linear, width 0s 0.3s linear;
  }

  .side-panel-toggle .side-panel {
    transform: translateX(0%);
    transition: transform 0.3s ease;
  }

  .side-panel-toggle .side-panel-tint {
    opacity: 1;
    width: 100%;
    height: 100vh;
    transition: opacity 0.2s linear;
  }

  .topbar .compose-list-item,
  .topbar .help-list-item {
    display: none;
  }

  #bs-menu > ul {
    height: 100%;
  }

  #bs-menu > ul > li {
    height: 100%;
    min-width: 50px;
    vertical-align: middle;
  }

  .topbar-search {
    height: 100%;
  }

  .topbar-search > input {
    width: 200px;
    color: #fff;
    opacity: 1;
  }

  .topbar-search > button {
    width: 50px;
    height: 100%;
    padding: 0;
  }

  .topbar .exit-search {
    display: inline-block;
    width: 0;
  }
  .topbar-search.hide-search {
    background-color: #2985c9;
  }

  .topbar-search.show-search {
    position: absolute;
    font-size: 16px;
    left: 5px;
    right: 5px;
    height: 50px;
    background-color: #2985c9;
    z-index: 1;
    animation: fade-in 0.5s ease;
  }

  .topbar-search.show-search .fa-search {
    color: rgba(255, 255, 255, 0.6);
  }

  .topbar-search.show-search .exit-search {
    width: 50px;
  }

  .thread-block {
    padding: 12px 15px;
  }

  .compose-circle {
    display: inline-block;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba(51, 187, 51, 0.95);
    text-align: center;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  }

  .compose-circle::before {
    display: inline-block;
    font-family: 'Ionicons';
    font-size: 24px;
    color: #fff;
    content: '\f37e';
    margin-top: 14px;
  }

  .l-profile-list .profiles {
    padding: 0;
  }

  .profile-head {
    margin-bottom: 0;
  }

  .profile-block {
    width: 100%;
  }

  .profile-block a {
    padding: 15px;
  }

  .org-members .label {
    padding-left: 0;
  }

  .org-members td {
    padding: 4px 0;
  }

  .org-members .first-name .label,
  .org-members .last-name .label,
  .org-members .role .label,
  .org-members .remove .label {
    display: none;
  }

  .org-members td.first-name,
  .org-members td.last-name {
    display: inline-block;
    width: auto;
    max-width: 85%;
    font-size: 16px;
    padding: 0 5px 0 0;
    word-wrap: break-word;
  }

  .org-members .remove {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 16px;
    padding: 0;
  }

  .login-footer {
    height: 140px;
  }

  .login .center-h {
    padding-bottom: 0;
  }

  .login .center-v {
    margin-top: 5vh;
    padding-bottom: 30px;
  }

  .form-control {
    font-size: 16px;
  }

  .copyright-text {
    margin: 10px 0;
    width: 100%;
  }

  /* This style required to trigger scrolling on iOS devices */
  .modal-dialog {
    height: calc(100% + 1px);
    -webkit-overflow-scrolling: auto;

    /* All -webkit-overflow-scrolling must be auto to work correctly */
  }

  /* This style required to add bottom margin back on Android */
  .modal-content {
    margin-bottom: 10px;
  }

  .progress-group {
    margin: 10px;
  }

  .progress-group .flexbox.progress-attachments {
    flex-direction: column;
  }

  .progress-group-head {
    margin: 5px 0;
  }

  .progress-attachments .flex {
    flex: 1;
    padding: 5px 0;
  }
}

/***********************************
 PRINT OVERRIDES
***********************************/

@media print {
  html,
  body,
  #main-body,
  #securemailapp,
  .main-panel,
  .l-fullscreen-scroll,
  .l-full-view,
  .l-details-view,
  .main-content,
  .main-panel,
  .modal.in,
  .modal.in .modal-dialog,
  .print {
    position: static;
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto !important;
    overflow: visible !important;
    overflow-y: visible !important;
    visibility: visible !important;
  }

  a[href]::after {
    content: none !important;
  }

  .topbar,
  .footer,
  .side-panel,
  .l-list-view,
  .panel-heading .btn-group,
  .btn-toolbar,
  .content-placeholder {
    display: none;
  }

  .panel-heading .navbar {
    display: block;
  }

  .panel-heading .navbar-nav.pull-left {
    width: 50%;
  }

  .message-unsecure {
    border: 1px solid #ddd;
  }

  .modal form .form-group {
    display: inline-block; /* Needed for CSS print styles to calculate height in chrome */
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 0 !important;
  }

  .modal form [class*='offset'] {
    margin-left: 0;
  }

  .modal form.form-horizontal .control-label {
    display: block;
    text-align: left;
    padding-bottom: 5px;
    width: 100%;
  }

  .modal form .col-sm-8,
  .modal form .col-sm-10 {
    width: 100%;
  }

  .modal form .control-label {
    display: block;
  }

  .modal form label.col-sm-2 + .col-sm-4,
  .modal form label.col-sm-2 + .col-sm-4 + .col-sm-4,
  .modal .col-sm-offset-2.col-sm-4,
  .modal .col-sm-offset-2.col-sm-4 + .col-sm-4 {
    display: inline-block; /* Needed for CSS print styles to calculate height in chrome */
    float: left;
    width: 50% !important;
  }

  .modal form .col-sm-5 {
    display: inline-block; /* Needed for CSS print styles to calculate height in chrome */
    float: left;
    width: 66.666% !important;
  }

  .modal form .col-sm-3 {
    display: inline-block; /* Needed for CSS print styles to calculate height in chrome */
    float: left;
    width: 33.333% !important;
  }

  .modal.in,
  .modal.in input,
  .modal.in textarea {
    font-size: 12px;
    line-height: 1.5;
  }

  .modal.in input {
    padding: 5px;
  }

  .modal-content {
    border: 0;
  }

  .modal-header {
    border: 0;
  }

  .letter .modal-body {
    font: 13px;
    line-height: 1.3;
    padding: 0 30px;
  }

  .letter blockquote {
    border-width: 0 0 0 1px;
    border-color: #ddd;
  }

  .form-horizontal .form-group,
  .form-horizontal p {
    margin: 0;
    padding: 5px;
  }

  .form-horizontal .form-group {
    line-height: 1;
  }

  .form-horizontal .col-sm-2,
  .form-horizontal .col-sm-8,
  .form-horizontal .col-sm-10 {
    text-align: left;
    margin-left: 0;
    width: 100%;
  }

  .form-horizontal .col-sm-offset-2 {
    margin-left: 0;
  }

  .form-horizontal .labels-right .control-label {
    text-align: left;
  }

  .hide-from-print {
    display: none;
  }
}
